import { Routes, Route } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { Loader, SEO } from './components/ui';
import Header from './components/header';
import Cookie from './components/cookie';

const Portfolio = lazy(() => import('./components/portfolio'));
const DesertSteel = lazy(() => import('./components/portfolio/desert-steel'));
const ECater = lazy(() => import('./components/portfolio/e-catering'));
const Origin = lazy(() => import('./components/portfolio/origin-coffee'));
const Mobility = lazy(() => import('./components/portfolio/mobility-plus'));
const Home = lazy(() => import('./components/home'));
const Footer = lazy(() => import('./components/footer'));
const Contact = lazy(() => import('./components/contact'));
const NotFound = lazy(() => import('./components/not-found'));

const App = () => {
 return (
    <>
      <Header />
      <Routes>
          <Route path="/" element={
            <Suspense fallback={<Loader />}>
              <SEO
                title={'Home'}
                slug={''}
                excerpt={"Hello, I'm Warren. I am a Front-end Shopify Developer based in Scotland."}
              />
              <Home />
            </Suspense>
          } />
          <Route path="/portfolio" element={
            <Suspense fallback={<Loader />}>
              <SEO
                title={'Portfolio'}
                slug={'portfolio'}
                excerpt={'Examples of recent work.'}
              />
              <Portfolio />
            </Suspense>
          } />
          <Route path="/portfolio/desert-steel" element={
            <Suspense fallback={<Loader />}>
              <DesertSteel />
            </Suspense>
          } />
          <Route path="/portfolio/e-catering" element={
            <Suspense fallback={<Loader />}>
              <ECater />
            </Suspense>
          } />
          <Route path="/portfolio/origin-coffee" element={
            <Suspense fallback={<Loader />}>
              <Origin />
            </Suspense>
          } />
          <Route path="/portfolio/mobility-plus" element={
            <Suspense fallback={<Loader />}>
              <Mobility />
            </Suspense>
          } />
          <Route path="/contact" element={
            <Suspense fallback={<Loader />}>
              <SEO
                title={'Contact'}
                slug={'contact'}
                excerpt={'Say Hello!'}
              />
              <Contact />
            </Suspense>
          } />
          <Route path="*" element={
            <Suspense fallback={<Loader />}>
              <SEO
                title={'404'}
                slug={'404'}
                excerpt={'Sorry, the page could not be found.'}
              />
              <NotFound />
            </Suspense>
          } />
      </Routes>
      <Cookie />
      <Footer />
    </>
 );
};

export default App;