import React, { useState } from 'react'
import styled from 'styled-components'

const MessageContainer = styled.div`
  background: ${p => p.theme.color.secondary};
  bottom: .5rem;
  right: 2rem;
  padding: 1.5rem;
  position: fixed;
  max-width: 320px;
  width: calc(100% - 4rem);
  box-shadow: -5px 5px 30px 0 rgba(0, 0, 0, 0.3);
  z-index: 1000;

  ${p => p.theme.layout.tablet} {
    padding: 1.25rem 1.5rem;
    right: 1.25rem;
    bottom: 1.25rem;
  }

  span {
    color: #fff;
    line-height: 1.5;
  }

  &.hide {
    display: none;
  }

  strong {
    font-weight: 900;
  }
`

const Button = styled.button`
  appearance: none;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  margin: 1rem 0 0 0;
  outline: none;
  padding: .5rem 1.25rem;
  transition: .75s background, .75s color;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: .15rem;
  font-size: .9rem;
  // border-radius: 1rem;

  ${p => p.theme.layout.tablet} {
    margin: 1rem 0 0 0;
    padding: .75rem 1.25rem;
    width: 100%;
  }

  &:hover {
    background: #fff;
    color: ${p => p.theme.color.secondary};
  }
`

const Cookie = () => {
  let state = false;
  
  if (localStorage.getItem('cookie_read')) {
    state = true
  }

  const [isHidden, setActive] = useState(state)

  const close = () => {
    localStorage.setItem('cookie_read', true);
  
    if (localStorage.getItem('cookie_read')) {
      setActive(true)
    }
  }

  return (
    <MessageContainer className={`${isHidden ? 'hide' : ''}`}>
      <span>By continuing to browse this site you are agreeing to the use of cookies.</span>
      <Button onClick={close}>Okay</Button>
    </MessageContainer>
  )
}

export default Cookie;