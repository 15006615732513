import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as logo } from '../../assets/logos/logo.svg'

export const LoaderOverlay = styled.div`
  background: white;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
`

export const LoaderWrapper = styled.div`
  height: 200px;
  justify-content: center;
  width: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
`

export const Logo = styled(logo)`
  height: 200px;
  fill: ${p => p.theme.color.primary};
`

export const Loader = ({ children }) => {
  const [isFetching, setFetch] = useState(true);

  useEffect(() => {
    if (isFetching) {
      setTimeout(()=>{
        setFetch(false);
       }, 500)
    }
  }, [isFetching])
  
  return (
    <>
      <LoaderOverlay className={`${isFetching ? 'fade-active' : 'fade-exit'}`}></LoaderOverlay>
      
      <LoaderWrapper className={`${isFetching ? 'fade-active' : 'fade-exit'}`}>
        <Logo />
      </LoaderWrapper>

      {children}
    </>
  )
}

Loader.defaultProps = {
  children: null,
  isFetching: false,
}
Loader.propTypes = {
  isFetching: PropTypes.bool,
  children: PropTypes.node,
}
