import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as arrow } from '../../assets/icons/long-arrow.svg';

export const Page = styled.div`
  min-height: calc(100vh - 96px - 68px - 2rem);
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;

  ${p => p.theme.layout.desktop} {
    min-height: calc(100vh - 132px - 88px - 4rem);
  }
`

export const Description = styled.p`
  text-align: center;
  color: ${p => p.theme.color.primary};
  font-size: 1.7rem;
  margin-bottom: 2rem;

  ${p => p.theme.layout.desktop} {
    font-size: 2rem;
    margin-bottom: 3.5rem;
  }
`

export const Heading = styled.h1`
  text-align: center;
  color: ${p => p.theme.color.primary};
  font-size: 4rem;
  margin-bottom: 1.4rem;

  ${p => p.theme.layout.desktop} {
    font-size: 7rem;
    margin-bottom: 2rem;
  }
`

export const Arrow = styled(arrow)`
  fill: ${p => p.theme.color.primary};
  transition: .75s fill;
`

export const Button = styled(NavLink)`
  appearance: none;
  background: transparent;
  color: ${p => p.theme.color.primary};
  cursor: pointer;
  font-family: CircularStd, sans-serif;
  font-size: .9rem;
  font-weight: 700;
  letter-spacing: .15rem;
  margin-bottom: 1.5rem;
  outline: none;
  padding: 1.25rem 2rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: .75s background, .75s color, .75s border;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;

  &:hover {
    background: #fff;
    color: ${p => p.theme.color.secondary};

    ${Arrow} {
      fill: ${p => p.theme.color.secondary};
    }
  }
`